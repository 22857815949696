import $ from "jquery";
import { breakpoint } from "../breakpoint";

let throttler;
const keyCodes = {
  tab: 9,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  enter: 13,
  space: 32,
  escape: 27
};

export class PrimaryNav {
  constructor($el) {
    this.$el = $el;
    this.$triggerlinks = this.$el.find(".js-primary-nav__child-trigger");
    this.$childLinks = this.$triggerlinks.find("li a");
    this.$activeItem = null; // Current context
    this.magicshrinkingnav = this.$el.hasClass("js-shrinkable");
    this.$headerItemsNoChildren = this.$el.find(".primary-nav__top-level-item a");
    this.$headerItemsWithChildren = this.$el.find(".primary-nav__top-level-item");
    this.$moreExpansion = this.$el.find(".primary-nav__more");


    this.bindEvents();

    if (this.magicshrinkingnav) {
      this.$more = this.$el.find(".js-primary-nav__more");
      this.$topLevelLinks = this.$el.find(".primary-nav__top-level-item:not(.js-primary-nav__more)");
      this.$moreLinks = this.$more.find(".primary-nav__sublevel-item");
      this.fontsAreReady();
    }
  }

  async fontsAreReady() {
    /* eslint-disable no-unused-vars */
    let ready = await document.fonts.ready;
    /* eslint-enable no-unused-vars */
    this.magicShrink();
  }

  bindEvents() {
    let thisMenu = this;

    if (this.magicshrinkingnav) {
      window.addEventListener("resize", function () {
        if (throttler) {
          window.clearTimeout(throttler);
        }
        throttler = setTimeout(() => {
          if (document.fonts.status === "loaded") {
            thisMenu.magicShrink();
          }
        }, 50);
      });
    }

    /* Key triggers for child links */
    /* Accessible menus are expected to behave as application menus */
    this.$childLinks.on("keydown", function (e) {
      /* Prevent triggerlinks behavior from happening */
      e.stopPropagation();

      let $sublevelTarget = $(this).parents(".primary-nav__sublevel");
      let $toplevelTarget = $(this).parents(".primary-nav__top-level-item");
      let $itemParent = $(this).parents(".primary-nav__sublevel-item");

      switch (e.keyCode) {
        case keyCodes.tab:
          $sublevelTarget.hide().attr("aria-hidden", "true");
          $toplevelTarget.removeClass("is-open");
          $toplevelTarget.focus(); /* Focus parent, normal event flow will take us to the next one */
          break;
        case keyCodes.escape:
          e.preventDefault();
          $sublevelTarget.hide().attr("aria-hidden", "true");
          $toplevelTarget.removeClass("is-open");
          $toplevelTarget.focus(); /* Focus parent and stay there due to preventDefault */
          break;
        case keyCodes.down:
          e.preventDefault();
          let $nextTarget = $itemParent.next(":not(.is-hidden)").length ? $itemParent.next(":not(.is-hidden)").find("a") : $sublevelTarget.children("li:not(.is-hidden)").first().children("a");
          $nextTarget.focus();
          break;
        case keyCodes.up:
          let $prevTarget = $itemParent.prev(":not(.is-hidden)").length ? $itemParent.prev(":not(.is-hidden)").find("a") : $sublevelTarget.children("li:not(.is-hidden)").last().children("a");
          $prevTarget.focus();
          break;
        default:
      }
    });

    /* Click triggers for top-level links */
    thisMenu.$triggerlinks.on("click", function(e) {
      if (e.target === this)
      {
        e.preventDefault();
      }
      let $childTarget = $(this).children(".primary-nav__sublevel");
      let newHiddenState = $childTarget.attr("aria-hidden") === "true" ? "false" : "true";

      if (newHiddenState === "false") {
        let otherTriggers = thisMenu.$triggerlinks.not(this);
        otherTriggers.removeClass("is-open");
        otherTriggers.children(".primary-nav__sublevel").hide().attr("aria-hidden", "true");
      }

      $(this).toggleClass("is-open");
      $childTarget.toggle().attr("aria-hidden", newHiddenState);
    });

    /* Key triggers for top-level links */
    /* Accessibile menus are expected to behave as application menus */
    thisMenu.$triggerlinks.on("keydown", function(e) {

      let $childTarget = $(this).children(".primary-nav__sublevel");
      let $firstChildLink = $(this).children(".primary-nav__sublevel").children("li:not(.is-hidden)").first().children("a");
      let $lastChildLink = $(this).children(".primary-nav__sublevel").children("li:not(.is-hidden)").last().children("a");
      let newHiddenState = $childTarget.attr("aria-hidden") === "true" ? "false" : "true";

      switch (e.keyCode) {
        case keyCodes.tab:
          $childTarget.hide().attr("aria-hidden", "true");
          $(this).removeClass("is-open");
          break;
        case keyCodes.escape:
          if (newHiddenState === "true") {
            /* If we wouldn't be closing the thing anyway, let esc bubble to close any parents */
            /* Otherwise, if it's not closed, don't ALSO close container, modal, or other menus... */
            e.stopPropagation();
          }
          e.preventDefault();
          $childTarget.hide().attr("aria-hidden", "true");
          $(this).removeClass("is-open");
          break;
        case keyCodes.enter:
        case keyCodes.space:
          e.preventDefault();
          $childTarget.toggle().attr("aria-hidden", newHiddenState);
          $(this).toggleClass("is-open");
          break;
        case keyCodes.down:
          e.preventDefault();
          $childTarget.show().attr("aria-hidden", "false");
          $firstChildLink.focus();
          break;
        case keyCodes.up:
          e.preventDefault();
          $childTarget.show().attr("aria-hidden", "false");
          $lastChildLink.focus();
          break;
        default:
      }
    });
  }

  /*
   * magicShrink
   * Handles "More+" dropdown on Now and Administrative type pages.
   * Triggered if wrapping element has the class js-shrinkable
   * Note that the markup must ALSO support shrinkability, requiring a certain amount of link
   *   duplication. See primary-navigation.md
   */
  magicShrink() {
    let thisMenu = this;

    let notActiveIn = ["small", "xsmall", "xxsmall"];
    let $officePrimaryNav = $(".js-header__primary-navigation");

    // Reset
    thisMenu.$topLevelLinks.css({ display: "" });
    thisMenu.$more.css({ display: "" }).removeClass("is-hidden");
    thisMenu.$moreLinks.css({ display: "none" }).addClass("is-hidden");
    $officePrimaryNav.css("top", "");

    // The only time the calculations need to happen is if we're not on mobile
    if (!(notActiveIn.indexOf(breakpoint()) > -1)) {
      let targetWidth = thisMenu.$el.width();
      let filledSoFar = thisMenu.$more.outerWidth(true);

      // Loop through the non-More top level links to measure the space
      thisMenu.$topLevelLinks.each(function (index) {
        let $moreCounterpart = $(thisMenu.$moreLinks.get($(this).index()));
        let isLastItem = index === thisMenu.$topLevelLinks.length - 1;
        let thisItemWidth = $(this).outerWidth(true);

        if (Math.round(filledSoFar + thisItemWidth) > targetWidth) {
          // Not enough room; shift this item to the More+ menu
          $(this).css({ display: "none" });
          $moreCounterpart.css({ display: "block" }).removeClass("is-hidden");
        } else {
          // There's enough room; keep this item and hide its More+ counterpart
          $(this).css({ display: "" });
          $moreCounterpart.css({ display: "none" }).addClass("is-hidden");
        }

        filledSoFar += thisItemWidth;

        // If this is the last top-level item, hide the More+ link if it's not needed
        if (isLastItem && Math.round(filledSoFar) <= targetWidth) {
          thisMenu.$more.css({ display: "none" }).addClass("is-hidden");
        }
      });
    } else {
      // Push the mobile navigation full-screen takeover down
      // so we can show the office header/title
      let officeTitleHeight = $(".js-header__left").height();

      if (officeTitleHeight > 0) {
        $officePrimaryNav.css("top", officeTitleHeight);
      }
    }
    let $allLinksArray = [];
    
    $(this.$headerItemsNoChildren).each(function( index ) {
      $allLinksArray.push($(this));
});

    this.$headerItemsWithChildren.each(function( index ) {
      $allLinksArray.push($(this));
    });

    $allLinksArray.forEach(function(link) {
      link.removeClass("hiddenNavOnLoad");
    });
  }
}
