/*
 * ResponsiveTable
 * Add pseudo headers to responsive table for use at mobile widths
 */
export class ResponsiveTable {
  constructor($el) {
    this.$el = $el;
    this.$el.id = $el.getAttribute("id");
    this.tableHeadersArray = [...this.$el.getElementsByTagName("th")];

    this.addResponsiveCellHeaders();
  }

  addResponsiveCellHeaders() {
    try {
      const styleElm = document.createElement("style");
      document.head.appendChild(styleElm);
      const styleSheet = styleElm.sheet;

      const tableHeaderText = this.tableHeadersArray.map(
        (tableHeader, index) => {
          // prettier-ignore
          const thisHeaderRule = "#" + this.$el.id + " td:nth-child(" + (index + 1) + ")::before {content:'" + tableHeader.innerHTML.trim() + "';}";

          styleSheet.insertRule(thisHeaderRule, styleSheet.cssRules.length);
        }
      );
    } catch (e) {
      console.log("addResponsiveCellHeaders(): " + e);
    }
  }
}
